<template>
  <div class="register">
    <van-nav-bar title="报名驾校" left-arrow @click-left="onClickLeft" />
    <div class="regmain">
      <div class="topBg"></div>
      <!-- <div class="hurdle">
        <p class="title">驾考通驾校</p>
        <p class="text">地址：内蒙古乌兰察布市驾考通驾校</p>
      </div> -->
      <!-- <p class="myregtext">我的报名</p> -->
      <div class="tab-wrap">
        <van-tabs v-model="registerClassify" type="line" :border="true" title-inactive-color="#6E7278" title-active-color="#171717" color="#0775F6" line-width="60px" @change="changeTab">
          <van-tab title="报名驾校" :title-style="registerClassify === 'study' ? activeStyle : notActiveStyle" name="study"></van-tab>
          <van-tab title="教练入驻" :title-style="registerClassify === 'coach' ? activeStyle: notActiveStyle" name="coach"></van-tab>
        </van-tabs>
      </div>
      <!-- 报名驾校 -->
      <div v-if="registerClassify === 'study'">
        <div class="box">
          <van-col span="8">
            <p class="title">车型</p>
          </van-col>
          <van-col span="16">
            <p class="text">C1小车།</p>
          </van-col>
        </div>
        <div class="box">
          <van-col span="8">
            <p class="title" v-if="isSign">您的姓名</p>
            <p class="titles" v-else>您的姓名</p>
          </van-col>
          <van-col span="16">
            <van-field v-if="isSign" v-model="userForm.name" placeholder="སྤྱོད་མཁན་གྱི་མིང་ནང་འཇུག་གནང་རོགས།" />
            <p v-else class="text">{{userForm.name}}</p>
          </van-col>
        </div>
        <div class="box">
          <van-col span="8">
            <p class="title" v-if="isSign">手机号码</p>
            <p class="titles" v-else>手机号码</p>
          </van-col>
          <van-col span="16">
            <van-field v-if="isSign" v-model="userForm.phone" placeholder="ཁ་པར་ཨང་གྲངས་ནང་འཇུག་གནང་རོགས།" />
            <p v-else class="text">{{userForm.phone}}</p>
          </van-col>
        </div>
        <div class="box" v-if="!isSign" style="border:none;">
          <van-col span="8">
            <p class="titles">状态</p>
          </van-col>
          <van-col span="16">
            <p class="text" style="color:#00992A">报名成功</p>
          </van-col>
        </div>
        <div v-if="isSign" class="btn" @click="rightnow" :style="show === true ? 'background: #0775F6;' : 'background: #eee;'">立即报名</div>
        <div v-else class="btn" @click="changemation">修改资料</div>
      </div>
      <!-- 教练入住 -->
      <div v-else>
        <div class="box">
          <van-col span="8">
            <p class="title" v-if="coachisSign">您的姓名</p>
            <p class="titles" v-else>您的姓名</p>
          </van-col>
          <van-col span="16">
            <van-field v-if="coachisSign" v-model="cocahForm.coachName" placeholder="ཁྱེད་ཀྱི་མིང་ནང་འཇུག་བྱེད་རོགས།" />
            <p v-else class="text">{{cocahForm.coachName}}</p>
          </van-col>
        </div>
        <div class="box">
          <van-col span="8">
            <p class="title" v-if="coachisSign">手机号码</p>
            <p class="titles" v-else>手机号码</p>
          </van-col>
          <van-col span="16">
            <van-field v-if="coachisSign" v-model="cocahForm.coachPhone" placeholder="ཁ་པར་ཨང་གྲངས་ནང་འཇུག་གནང་རོགས།" />
            <p v-else class="text">{{cocahForm.coachPhone}}</p>
          </van-col>
        </div>
        <div class="box">
          <van-col span="8">
            <p class="title" v-if="coachisSign">您的驾校</p>
            <p class="titles" v-else>您的驾校</p>
          </van-col>
          <van-col span="16">
            <van-field v-if="coachisSign" v-model="cocahForm.driverName" placeholder="ཁ་ལོ་བའི་མིང་ནང་འཇུག་གནང་རོགས།" />
            <p v-else class="text">{{cocahForm.driverName}}</p>
          </van-col>
        </div>
        <div class="box" v-if="!coachisSign" style="border:none;">
          <van-col span="8">
            <p class="titles">状态</p>
          </van-col>
          <van-col span="16">
            <p class="text" style="color:#00992A">报名成功</p>
          </van-col>
        </div>
        <div v-if="coachisSign" class="btn" @click="coachNow" :style="coachShow === true ? 'background: #0775F6;' : 'background: #eee;'">立即报名</div>
        <div v-else class="btn" @click="coachMation">修改资料</div>
      </div>
    </div>
  </div>
</template>

<script>
import { isSigin, getSign, coachSign,  coachDetail} from '@/api/sigin';
export default {
  name: "",
  components: {},
  props: {},
  data() {
    return {
      activeStyle: 'font-size:18px',
      notActiveStyle: 'font-size:14px',
      registerClassify: 'study',  //注册类型 - 默认选择报名驾校
      userForm: {name: '', phone: ''},
      cocahForm: {coachName: '', coachPhone: '', driverName: ''},
      show: true,
      isSign: false,
      coachisSign: false,
      coachShow: true
    };
  },
  computed: {},
  watch: {},
  created() {
    this.getstatus()
  },
  mounted() {},
  methods: {
    // 切换tab
    changeTab(e) {
      this.registerClassify = e
      if(e === 'coach') {
        this.getcoachStatus()
      } else {
        this.getstatus()
      }
    },
    // 教练修改资料
    coachMation() {
      this.coachisSign = true
      this.coachShow = true
    },
    // 返回上一页
    onClickLeft() {
      this.$router.back(-1);
    },
    // 学员修改信息
    changemation() {
      this.isSign = true
      this.show = true
    },
    // 获取学员状态
    getstatus() {
      getSign().then(res => {
        if (res.data !== null) {
          this.userForm.name = res.data.name
          this.userForm.phone = res.data.phone
          this.show = false
          this.isSign = false
        } else {
          this.show = true
          this.isSign = true
        }
      })
    },
    // 学员报名接口
    regist() {
      const data = {
        name: this.userForm.name,
        phone: this.userForm.phone,
        type: 'C1小车'
      }
      isSigin(data).then(res => {
        if (res.httpStatus === 200) {
          this.$notify({ type: 'success', message: 'མིང་ཐོ་འགོད་པར་རྒྱལ་ཁ་ཐོབ་པ།,སྐར་ཆ་གསུམ་གྱི་རྗེས་སུ་མི་སྒེར་གྱི་ལྟེ་གནས་སུ་ལྡིང་།' })
          setTimeout(() => {
            this.$router.push('/self')
          }, 3000);
        }
      })
    },
    // 学员报名事件
    rightnow() {
      if (this.show) {
        const myreg = /^[1][3,4,5,7,8][0-9]{9}$/;
        if (this.userForm.name === '') {
          this.$notify('མིང་ནང་འཇུག་བྱེད་རོགས།');
          return false
        } else if (!myreg.test(this.userForm.phone)) {
          this.$notify('ཡང་དག་པའི་ཁ་པར་ཨང་གྲངས་ནང་འཇུག་གནང་རོགས།');
          return false
        } else {
          this.show = false
          this.regist()
        }
      } else {
        return
      }
      
    },
    // 接口教练申请
    coachregist() {
      const data = {
        coachName: this.cocahForm.coachName,
        coachPhone: this.cocahForm.coachPhone,
        driverName: this.cocahForm.driverName
      }
      coachSign(data).then(res => {
        if (res.httpStatus === 200) {
          this.$notify({ type: 'success', message: 'མིང་ཐོ་འགོད་པར་རྒྱལ་ཁ་ཐོབ་པ།,སྐར་ཆ་གསུམ་གྱི་རྗེས་སུ་མི་སྒེར་གྱི་ལྟེ་གནས་སུ་ལྡིང་།' })
          setTimeout(() => {
            this.$router.push('/self')
          }, 3000);
        }
      })
    },
    // 教练申请
    coachNow() {
      if (this.coachShow) {
        const myreg = /^[1][3,4,5,7,8][0-9]{9}$/;
        if (this.cocahForm.coachName === '') {
          this.$notify('མིང་ནང་འཇུག་བྱེད་རོགས།')
          return false
        } else if (!myreg.test(this.cocahForm.coachPhone)) {
          this.$notify('ཡང་དག་པའི་ཁ་པར་ཨང་གྲངས་ནང་འཇུག་གནང་རོགས།')
          return false
        } else if (!this.cocahForm.driverName) {
          this.$notify('ཁ་ལོ་བའི་མིང་ནང་འཇུག་གནང་རོགས།')
          return false
        } else {
          this.coachregist()
          this.coachShow = false
        }
      }
    },
    // 获取
    getcoachStatus() {
      coachDetail().then(res => {
        if (res !== false) {
          this.coachisSign = false
          this.coachShow = false
          this.cocahForm = res.data
        } else {
          this.coachisSign = true
          this.coachShow = true
          this.cocahForm = {coachName: '', coachPhone: '', driverName: ''}
        }
      })
    }
  },
};
</script>

<style scoped lang="scss">
.active-style{
  font-size: 18px !important;
}
.register {
  display: flex;
  flex-direction: column;
  .regmain{
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    .tab-wrap{
      width:100%;
      border-bottom:1px solid #eee;
      .active-style{
        font-size: 18px;
      }
    }
    .topBg {
      width: 375px;
      height: 211px;
      background: url('../assets/image/applybg.png') no-repeat;
      background-size: 100% 100%;
    }
    .hurdle{
      width: 327px;
      height: 80px;
      background: #FFFFFF;
      box-shadow: 0px 2px 4px 0px rgba(160, 160, 160, 0.4);
      border-radius: 4px;
      margin: -15px auto 0;
      padding-left: 24px;
      padding-top: 22px;
      .title{
        font-size: 22px;
        font-family: SourceHanSansSC-Medium, SourceHanSansSC;
        font-weight: 500;
        color: #171717;
        line-height: 32px;
      }
      .text{
        padding-top: 5px;
        font-size: 14px;
        font-family: SourceHanSansSC-Regular, SourceHanSansSC;
        font-weight: 400;
        color: #6E7278;
        line-height: 20px;
      }
    }
    .myregtext{
      padding-top: 24px;
      font-size: 22px;
      font-family: SourceHanSansSC-Medium, SourceHanSansSC;
      font-weight: 500;
      color: #0775F6;
      line-height: 32px;
      letter-spacing: 1px;
    }
    .box{
      width: 304px;
      height: 31.5px;
      margin-top: 22px;
      border-bottom: 1px solid #F3F3F3;
      &:nth-child(4) .title::after{
        content: "*";
        color: red;
        padding-left: 5px;
      }
      &:nth-child(5) .title::after{
        content: "*";
        color: red;
        padding-left: 5px;
      }
      &:nth-child(6) .title::after{
        content: "*";
        color: red;
        padding-left: 5px;
      }
      .title{
        font-size: 16px;
        font-family: SourceHanSansSC-Regular, SourceHanSansSC;
        font-weight: 400;
        color: #171717;
        line-height: 24px;
      }
      .titles{
        font-size: 16px;
        font-family: SourceHanSansSC-Regular, SourceHanSansSC;
        font-weight: 400;
        color: #171717;
        line-height: 24px;
      }
      .text{
        font-size: 16px;
        font-family: SourceHanSansSC-Regular, SourceHanSansSC;
        font-weight: 400;
        color: #171717;
        line-height: 24px;
      }
      ::v-deep .van-cell{
        padding: 0;
      }
    }
    .btn{
      width: 240px;
      height: 36px;
      background: #0775F6;
      border-radius: 18px;
      text-align: center;
      line-height: 36px;
      margin: auto;
      margin-top: 32px;
      font-size: 18px;
      font-family: SourceHanSansSC-Medium, SourceHanSansSC;
      font-weight: 500;
      color: #FCFBFB;
    }
  }
}
</style>
